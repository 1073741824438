export class SignaturesWalker {
  constructor(editor, repository) {
    this.editor = editor
    this.repository = repository
    this.currentNode = null
  }

  setCurrentNode(node){
    this.currentNode = node
  }

   selectNode(node){
    let wrapper = document.querySelector('.sign-view')
    if (node && node.position){
      this.currentNode = node
      this.editor.chain()
        .focus()
        .setNodeSelection(node.position)
        .setLastClickedSignatureId(node.id)
        .command( () => {
          let windowTopPosition = window.scrollY
          let signatureNode = document.querySelector(`[data-signature-node-id="${node.id}"]`);

          let count = signatureNode.offsetTop - wrapper.scrollTop - 60
          wrapper.scrollBy({top: count, left: 0})
          //restore window position
          window.scrollTo({top: windowTopPosition})

          return true
        })
        .run()

    }
  }

  first(){
    this.currentNode = this.repository.getAll().shift()
    this.selectNode(this.currentNode)
  }

  last(){
    this.currentNode = this.repository.getAll().pop()
    this.selectNode(this.currentNode)
  }

  next(){
    if (!this.currentNode) return this.first()

    if (this.getCurrentNodeIndex() >= 0 && this.repository.getAll()[this.getCurrentNodeIndex() + 1]){
      this.selectNode(this.repository.getAll()[this.getCurrentNodeIndex() + 1])
    }
  }

  prev(){
    if (!this.currentNode) return this.last()

    if (this.getCurrentNodeIndex() > 0 && this.repository.getAll()[this.getCurrentNodeIndex() - 1]){
      this.selectNode(this.repository.getAll()[this.getCurrentNodeIndex() - 1])
    }
  }

  firstUnsigned(){
    this.currentNode = this.repository.query().unsigned().get().shift()
    this.selectNode(this.currentNode)
  }
  lastUnsigned(){
    this.currentNode = this.repository.query().unsigned().get().pop()
    this.selectNode(this.currentNode)
  }
  nextUnsigned(){
    if (!this.currentNode) return this.firstUnsigned()
    let allNodes = this.repository.getAll()
    let nextIndex = this.getNextUnsignedNodeIndex()
    if (nextIndex !== undefined){
      this.selectNode(allNodes[nextIndex]);
    }
  }
  prevUnsigned(){
    if (!this.currentNode) return this.firstUnsigned()
    let allNodes = this.repository.getAll()
    let prevIndex = this.getPrevUnsignedNodeIndex()
    if (prevIndex !== undefined){
      this.selectNode(allNodes[prevIndex])
    }
  }
  getNextUnsignedNodeIndex(){
    let currentNodeIndex = this.getCurrentNodeIndex()

    let unsignedNodes = this.repository.query().unsigned().get()
    let allNodes = this.repository.getAll()
    let unsignedIndexes = []

    unsignedNodes.forEach(node => {
      let index = allNodes.findIndex(n => n.id === node.id)
      if (index >= 0){
        unsignedIndexes.push(index)
      }
    })

    return unsignedIndexes.find(index => index > currentNodeIndex)
  }
  getPrevUnsignedNodeIndex(){
    let currentNodeIndex = this.getCurrentNodeIndex()

    let unsignedNodes = this.repository.query().unsigned().get()
    let allNodes = this.repository.getAll()
    let unsignedIndexes = []

    unsignedNodes.forEach(node => {
      let index = allNodes.findIndex(n => n.id === node.id)
      if (index >= 0){
        unsignedIndexes.push(index)
      }
    })
    unsignedIndexes.sort((a, b) =>  b - a )

    return unsignedIndexes.find(index => index < currentNodeIndex)
  }



  getCurrentNodeIndex(){
    if (!this.currentNode) return this.repository.getAll().length ? 0 : -1

    return this.repository.getAll().findIndex(n => {
      return n.id === this.currentNode.id
    })
  }
}
