<template :key="key">
  <div class="taskFillNSign">
    <CCard class="mb-4 customCardTab">
      <card-head-navigation v-if="task" :task="task" />
      <CCardBody class="p-0">
        <div v-if="task" class="align-items-stretch row mx-0">
          <div
            v-if="authUserIsSender || authUserIsReceiver"
            class="col-lg-4 with-right-border"
          >
            <h2
              class="title d-flex justify-content-between align-items-center align-items-lg-start mt-4"
            >
              {{ $t('Fill & Sign') }}
              <section-visibility-toggle
                class="d-lg-none"
                @update="(value) => (sectionsVisibility.navigation = value)"
              />
            </h2>

            <CCollapse :visible="sectionsVisibility.navigation">
              <div class="mb-5">
                {{
                  $t(
                    'Please fill-out, sign and initial all required fields. Once you are finished, click the submit button. You will automatically receive a copy of the signed document via email after submitting.',
                  )
                }}
              </div>
              <div
                class="d-grid d-sm-flex flex-column justify-content-center align-items-center gap-3 my-4"
              >
                <button
                  class="btn btn-lg btn-primary"
                  :disabled="!policiesConfirmed"
                  @click.prevent="startSigning"
                >
                  {{ $t('Begin Signing') }}
                </button>
                <submit-btn
                  :loading="finalizing"
                  class="btn btn-lg btn-primary"
                  :disabled="!allSignaturesFilledOut"
                  @click.prevent="handleSubmit"
                  >{{ $t('Finalize & Submit') }}</submit-btn
                >
                <p v-if="!contractReadyForSign">
                  {{
                    $t(
                      'Waiting for the Subcontractor to complete the assignment',
                    )
                  }}
                </p>
              </div>
              <div v-if="contractReadyForSign" class="d-flex">
                <div style="flex: 0 0 30px">
                  <CFormCheck v-model="policiesConfirmed" type="checkbox" />
                </div>
                <div
                  class="clickable"
                  @click="policiesConfirmed = !policiesConfirmed"
                >
                  I confirm that I have read and understood the "Electronic
                  Record and Signature Disclosure" and consent to use electronic
                  records and signatures.
                </div>
              </div>
              <keep-alive>
                <contract-signatures-navigator
                  v-if="signingStarted && contract"
                  :editor="tiptap"
                  :contract="contract"
                  :nodes-walker="nodesWalker"
                  @cancel-signing="cancelSigning"
                  @navigation-state-update="handleNavigationStateUpdated"
                />
              </keep-alive>
            </CCollapse>
          </div>
          <div v-else class="col-lg-4">
            <h4 class="mt-5">
              {{ $t("You don't have permission to sign this document") }}
            </h4>
          </div>
          <div class="col-lg-8 px-0">
            <div class="dropdown-divider d-block d-lg-none"></div>

            <h4
              class="d-lg-none d-flex justify-content-between title align-items-center align-items-lg-start mt-2"
            >
              {{ task.contract.name }}
              <section-visibility-toggle
                @update="(value) => (sectionsVisibility.document = value)"
              />
            </h4>
            <CCollapse :visible="sectionsVisibility.document">
              <editor-content-viewer
                ref="editorContainer"
                :editor="tiptap"
                view-name="sign"
              />

              <!--              <editor-content ref="editorContainer" class="mt-3" :editor="tiptap" />-->
            </CCollapse>
          </div>
        </div>
        <div
          v-else
          class="preloader d-flex justify-content-center align-items-center"
        >
          <CSpinner size="lg" color="primary" />
        </div>
      </CCardBody>
    </CCard>
    <confirmation-modal ref="dialogModal" />
  </div>
</template>

<script>
import errors from '@/mixin/errors'
import CardHeadNavigation from '../components/CardNavigation'
import { initContractEditorWithContent } from '@/utils/editor/editorFactory'
import ContractSignaturesNavigator from '@/views/Projects/Project/Tasks/Task/View/TaskSign/Partials/ContractSignaturesNavigator'
import { editorModes } from '@/utils/editor/extensions/utils'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import { parseError } from '@/utils/api'
import SubmitBtn from '@/components/SubmitBtn'
import actorTypes from '@/utils/editor/extensions/signature/actorTypes'
import { SignaturesWalker } from '@/utils/editor/tools/signaturesWalker'
import { SignaturesRepository } from '@/utils/editor/tools/signaturesRepository'
import taskStatuses from '@/utils/constants/taskStatuses'
import SectionVisibilityToggle from '@/components/Forms/CompanyUsersEditForm/SectionVisibilityToggle'
import EditorContentViewer from '@/components/Editor/EditorContentViewer'
import Gate from '@/services/Gate/Gate'
import router from '../../../../../../../router'

export default {
  name: 'TaskSign',
  components: {
    SubmitBtn,
    ContractSignaturesNavigator,
    CardHeadNavigation,
    ConfirmationModal,
    SectionVisibilityToggle,
    EditorContentViewer,
  },
  mixins: [errors],
  inject: ['toast'],
  data() {
    return {
      policiesConfirmed: false,
      tiptap: null,
      task: null,
      contract: null,
      signingStarted: false,
      allSignaturesFilledOut: false,
      signedNodesCount: 0,
      unsignedNodesCount: 0,
      key: 0,
      finalizing: false,
      nodesWalker: null,
      sectionsVisibility: {
        navigation: true,
        document: true,
      },
    }
  },
  computed: {
    authUserIsSender() {
      return (
        parseInt(this.contract?.sending_signer?.id) ===
        parseInt(this.authUser.id)
      )
    },
    authUserIsReceiver() {
      return (
        parseInt(this.contract?.receiving_signer?.id) ===
        parseInt(this.authUser.id)
      )
    },
    isPendingSignature() {
      return [
        taskStatuses.CONTRACT_PENDING_EXTERNAL_SIGNATURE,
        taskStatuses.CONTRACT_PENDING_INTERNAL_SIGNATURE,
      ].includes(this.task.status)
    },
    contractReadyForSign() {
      //user should be one of signers
      if (!this.authUserIsSender && !this.authUserIsReceiver) return false

      //task status is Pending[for signature]
      if (!this.isPendingSignature) return false

      //contract signed by receiver before sender
      if (this.authUserIsSender) {
        return this.contract.receiver_signed_at !== null
      }
      //contract signed by receiver after sender
      if (this.authUserIsReceiver) {
        return this.contract.sender_signed_at === null
      }
      return false
    },
  },
  watch: {
    policiesConfirmed(to) {
      if (!to) {
        this.cancelSigning()
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handleNavigationStateUpdated({ unsignedNodesCount, signedNodesCount }) {
      this.allSignaturesFilledOut =
        unsignedNodesCount === 0 && signedNodesCount > 0
      this.signedNodesCount = signedNodesCount
      this.unsignedNodesCount = unsignedNodesCount
    },
    async cancelSigning() {
      //user should confirm action if some signatures has been added
      if (this.signedNodesCount > 0) {
        if (
          !(await this.$refs.dialogModal.confirm({
            text: this.$t('All signed fields will be lost'),
          }))
        )
          return (this.policiesConfirmed = true)
      }

      this.signingStarted = false
      this.tiptap
        .chain()
        .setMode(editorModes.MODE_READ_ONLY)
        .setContent(this.contract.content)
        .run()
    },
    initSignatureWalker() {
      let actor =
        parseInt(this.contract?.sending_signer?.id) ===
        parseInt(this.authUser.id)
          ? actorTypes.ACTOR_SENDER
          : actorTypes.ACTOR_RECEIVER

      this.tiptap.chain().setActor(actor).run()

      return new SignaturesWalker(
        this.tiptap,
        new SignaturesRepository(this.editor, () =>
          this.tiptap.commands
            .getNodesByType('signature')
            .filter((n) => n.actor === actor),
        ),
      )
    },
    startSigning() {
      this.nodesWalker = this.initSignatureWalker()
      window.walker = this.nodesWalker

      if (!this.nodesWalker.repository.getAll().length) {
        return this.$refs.dialogModal.confirm({
          title: this.$t('Signing is not available'),
          text: this.$t(
            'The document does not contain fields available for signing by you',
          ),
          confirmText: this.$t('OK'),
        })
      }
      this.signingStarted = true
      this.tiptap.chain().setMode(editorModes.MODE_SIGN).run()
    },
    handleSubmit() {
      this.finalizing = true
      const html = this.tiptap.getHTML()
      this.$http.tasks
        .finalize({ task_id: this.task.id, contract_content: html })
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data && res.data.message) {
              this.toast('success', res.data.message)
            }

            this.$store.commit('setTaskEntity', {})
            await Gate.can(
              'finishView',
              'task',
              this.$route.params.task_id,
            ).then(async (res) => {
              if (res) return await this.$router.push({ name: 'TaskFinish' })
            })
          }
        })
        .catch((error) => {
          let err = parseError(error)
          if (err.status === 422) {
            this.validationErrors = err.validationMessages
          } else {
            this.toast('error', err.message)
          }
        })
        .finally(() => {
          this.finalizing = false
        })
    },
    fetchData() {
      this.$http.tasks
        .getTask({ params: { task_id: this.$route.params.task_id } })
        .then((res) => {
          if (
            ![
              taskStatuses.CONTRACT_PENDING_EXTERNAL_SIGNATURE,
              taskStatuses.CONTRACT_PENDING_INTERNAL_SIGNATURE,
            ].includes(res.data.data.status) && this.$route.name === 'TaskSign'
          ) {
            return this.$router.push({ name: 'TaskContracts', params: this.$route.params })
          }

          this.task = res.data.data
          this.contract = this.task.contract
          this.initEditor()
        })
    },
    initEditor() {
      this.tiptap = initContractEditorWithContent({
        html: this.task.contract.content,
        contract: this.task.contract,
        editable: false,
        mode: editorModes.MODE_READ_ONLY,
      })
      window.editor = this.tiptap
    },
  },
}
</script>

<style scoped lang="scss">
.taskFillNSign {
  .card {
    border-radius: 10px;
  }
  .dropdown-divider {
    margin: 0.7rem -12px 1rem -12px;
  }
  .with-right-border {
    @media (min-width: 991px) {
      border-right: 4px solid #ebedef;
    }
  }
  .tasks-module-navigation {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .title {
    color: #303c54;

    @media (min-width: 991px) {
      font-size: 22px;
      font-weight: 600;
    }
    @media (max-width: 991px) {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
      padding: 0.5rem 16px;
      margin: 0 -16px;
    }
  }
  button[disabled] {
    background-color: grey;
  }
  .preloader {
    min-height: 400px;
  }
}
</style>
