<template>
  <span
    class="modal__btn clear btn-link clickable"
    @click="toggle"
  >
    <img :class="{'chevron-up': visible}"
         src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='303C54'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" alt="chevron up" />
  </span>
</template>

<script>
export default {
  name: 'SectionVisibilityToggle',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['update'],
  data(){
    return {
      visible: this.isVisible,
    }
  },
  methods: {
    toggle(){
      this.visible = !this.visible
      this.$emit('update', this.visible)
    },
  },
  watch: {
    isVisible(to){
      this.visible = to
    }
  }
}
</script>

<style scoped lang="scss">
  button {
    padding-right: 0 !important;

  }
  img {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 3px;
    &.chevron-up {
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
    }
  }

</style>
