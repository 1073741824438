<template>
  <div class="navigation-box my-4">
    <div class="head">
      <span>Enter your signature</span>
      <span class="btn-close clickable btn-sm" @click="cancelSigning"></span>
    </div>
    <div class="body">
      <div class="nav-buttons">
        <button class="btn btn-link" style="color: #2f2292" :disabled="!prevNodeAvailable" @click.prevent="nodesWalker.prevUnsigned">Previous</button>
        <button class="btn btn-link" style="color: #2f2292" :disabled="!nextNodeAvailable" @click.prevent="nodesWalker.nextUnsigned">Next</button>
        <CButton :disabled="!nodesWalker.currentNode" color="primary" @click.prevent="setSignatureToSelectedNode">Add</CButton>
      </div>
      <div class="mt-3">signed <strong>{{ signedNodesCount }}</strong> from <strong>{{ allNodesCount }}</strong></div>
    </div>
    <confirmation-modal ref="notificationModal" />
  </div>
</template>

<script>
import { editorEvents } from '@/utils/editor/extensions/utils';
import ConfirmationModal from '@/components/Modals/ConfirmationModal';

export default {
  name: 'ContractSignaturesNavigator',
  components: { ConfirmationModal },
  emits: ['cancel-signing', 'navigation-state-update'],
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
    contract: {
      type: Object,
      default: () => ({})
    },
    nodesWalker: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    // nodesWalker: null,
    allNodesCount: 0,
    signedNodesCount: 0,
    nextNodeAvailable: true,
    prevNodeAvailable: true,
  }),
  methods: {
    async setSignatureToSelectedNode(){
      if (this.nodesWalker.currentNode){
        let selectedNode = document.querySelector(
          `span[data-signature-node-id="${this.nodesWalker.currentNode.id}"]`,
        )
        if (selectedNode){
          selectedNode.click()
        }
      }
    },
    async cancelSigning(){
      this.$emit('cancel-signing')
    },
    updateNavigationState(){
      this.allNodesCount = this.nodesWalker.repository.getAll().length
      let unsignedCount = this.nodesWalker.repository.query().unsigned().get().length
      this.signedNodesCount = this.allNodesCount - unsignedCount
      this.nextNodeAvailable = this.nodesWalker.currentNode ? this.nodesWalker.getNextUnsignedNodeIndex() >= 0 : unsignedCount > 0
      this.prevNodeAvailable = this.nodesWalker.currentNode ? this.nodesWalker.getPrevUnsignedNodeIndex() >= 0 : unsignedCount > 0
      this.$emit('navigation-state-update', {
        signedNodesCount: this.signedNodesCount,
        unsignedNodesCount: unsignedCount,
      })
    },
    notifySignatureNotDefined(){
      this.$refs.notificationModal.confirm({
        title: this.$t('Signature not defined'),
        text: this.$t('Seems like signature has not been configured. Please, go to the profile settings page to configure your signatures'),
        confirmText: this.$t('Go to settings'),
      }).then(res => {
        if (res){
          let {href} = this.$router.resolve({name: 'SettingCompanyUsersEdit', params: {'id': this.authUser.id}})
          const link = document.createElement('a')
          link.href = href
          link.target = '_blank'
          link.click()
        }
      })
    }
  },
  activated() {
    setTimeout(() => {
      this.nodesWalker.firstUnsigned()
    }, 50)
  },
  created(){
    setTimeout(() => {
      this.nodesWalker.firstUnsigned()
    }, 50)

    //handle selection change to sync actual current selected signature node in the nodesWalker
    this.editor.on('selectionUpdate', ({ editor }) => {
      if (editor.view.state?.selection?.node && editor.view.state?.selection?.node?.type.name === 'signature'){
        this.nodesWalker.setCurrentNode({
          ...editor.view.state.selection.node.attrs,
          ...{ position: editor.view.state.selection.from }
        })
      } else {
        this.nodesWalker.setCurrentNode(null)
        editor.commands.setLastClickedSignatureId(null)
      }
      this.updateNavigationState()
    })

    this.updateNavigationState()

    this.editor.commands.getEmitter().on(editorEvents.EVENT_SIGNATURE_SET, this.updateNavigationState)
    this.editor.commands.getEmitter().on(editorEvents.EVENT_SIGNATURE_UNSET, this.updateNavigationState)
    this.editor.commands.getEmitter().on(editorEvents.EVENT_SIGNATURE_EMPTY, this.notifySignatureNotDefined)
  },

}
</script>

<style scoped lang="scss">
.navigation-box {
  border: 1px solid #B1B7C1;
  border-radius: 5px;
  padding: 18px;
  .head {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .body {
    padding-top: 10px;
    .nav-buttons{
      button:first-child {
        padding-left: 0;
      }
      button {
        margin-right: 1rem;
        //text-decoration: none;
      }
    }
  }
}

</style>
