export class SignaturesRepository {
  constructor(editor, query) {
    this._query = query
    this.modificators = []
  }

  getAll() {
    return this._query()
  }

  query(){
    this.modificators = []
    return this
  }
  unsigned(){
    this.modificators.push((nodes) => nodes.filter(n => n.imageData === null))
    return this
  }

  get(){
    let mod = this.modificators.shift()
    let result = this._query()
    while (mod){
      result = mod(result)
      mod = this.modificators.shift()
    }
    return result
  }
}
